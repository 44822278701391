import React from "react"

import styled from "styled-components"

export default function Profile({
  manufacturer,
  price,
  regulations,
  origin,
  style,
  gargano,
}) {
  return (
    <ProfileWrapper>
      <Section>
        <Row>
          <Left>
            <h3>Owner</h3>
          </Left>
          <Right></Right>
          <Left>
            <p>Manufacturer: </p>
          </Left>
          <Right>{manufacturer}</Right>

          <Left>
            <p>Price approx: </p>
          </Left>
          <Right>{price} &#8364;</Right>
        </Row>
      </Section>

      <Section>
        <Row>
          <Left>
            <h3>Origins</h3>
          </Left>
          <Right></Right>
          <Left>
            <p>Terroir: </p>
          </Left>
          <Right>{origin}</Right>
          <Left>
            <p>Regulations: </p>
          </Left>
          <Right>{regulations}</Right>
        </Row>
      </Section>

      <Section>
        <Row>
          <Left>
            <h3>Classification</h3>
          </Left>
          <Right></Right>
          <Left>
            <p>Style: </p>
          </Left>
          <Right>{style}</Right>
          <Left>
            <p>Gargano: </p>
          </Left>
          <Right>{gargano}</Right>
        </Row>
      </Section>
    </ProfileWrapper>
  )
}

const ProfileWrapper = styled.div`
  margin-top: 1rem;
  grid-area: profile;

  /* font-size: 1.15rem; */
  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  width: 100%;
  margin: 10px auto 30px;

  @media (min-width: 600px) {
    width: 96%;
  }

  h3 {
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;

    text-transform: uppercase;
    text-align: right;
    margin-bottom: 10px;
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: baseline;

  @media (min-width: 900px) {
    grid-template-columns: 120px 1fr;
  }
`

const Left = styled.div`
  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;
  text-align: right;
  margin-top: 4px;

  /* justify-content: center; */
  /* padding-bottom: 2px; */
  /* font-size: 1.2rem; */

  p {
    font-size: 1rem;

    @media (min-width: 800px) {
      font-size: 1.1rem;
    }
    @media (min-width: 900px) {
      font-size: 1.2rem;
    }
  }
`
const Right = styled.div`
  padding-left: 10px;
  text-align: left;

  font-size: 1rem;

  @media (min-width: 800px) {
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    font-size: 1.2rem;
  }
`
